import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { CATEGORY_WITH_PRODUCT_FRAGMENT } from '@/graphql/_Fragments/Category/WithProduct';
import { EXHIBITOR_URL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitorUrl/Base';
import DomainContextParams from '@/utils/types/DomainContextParams';

export const EXHIBITOR_LOGO_FRAGMENT = `
  fragment exhibitorLogoFragment on Exhibitor {
    id
    uid
    name
    logoFileResource {
      ...fileResourceBaseFragment
    }
    categoriesInContext(context: "${DomainContextParams.company}") {
      ...categoryWithProductFragment
    }
     urls {
      ...exhibitorUrlBaseFragment
    }
  }
  ${CATEGORY_WITH_PRODUCT_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${EXHIBITOR_URL_BASE_FRAGMENT}
`;
